import '@usb-shield/react-grid/dist/library/styles/index.css'
import { useRef, useState, createRef } from 'react';
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { PageHeaderMenuItem } from '../pageHeader.model'
import { USBColumn } from '@usb-shield/react-grid'
import pageHeaderStyles from '../pageHeader.module.scss'
import styles from '../desktopNavBar.module.scss'
import { getLastStepupDuration } from '@/utils/auth-widget/auth-widget'
import { fetchAccessibleProductLink } from '@/utils/products/global-element-product-access-check'
import { useCookies } from 'react-cookie'
import { setOverlayStatus } from './HeaderTierTop'
import { useDetectClickOutside } from 'react-detect-click-outside';
import { hideOverlayAndMenu } from './HideOverlayAndMenu';


/**
 * Resets the navigation arrows by removing the `upArrow` class from all list items
 * within the main unordered list.
 *
 * This function selects all `li` elements within `ul.main` and iterates over them,
 * removing the `upArrow` class from each.
 */
export let resetNavArrows = () => {
  const menuList = Array.from(document.querySelectorAll('ul.main li'))

  for (const parentMenu of menuList) {
    parentMenu.classList.remove(styles.upArrow)
  }
}

/**
 * Updates the state of the profile menu arrow flag and overlay status.
 *
 * @param profileMenuArrowFlag - The current state of the profile menu arrow flag.
 * @param setProfileMenuArrowFlag - Function to update the profile menu arrow flag state.
 * @param setOverlayStatus - Function to update the overlay status.
 */
const updateProfileMenuArrowFlag = (
  profileMenuArrowFlag: boolean,
  setProfileMenuArrowFlag: Function,
  setOverlayStatus: Function
) => {
  if (profileMenuArrowFlag) {
    setProfileMenuArrowFlag(true)
    setOverlayStatus(true)
  } else {
    setProfileMenuArrowFlag(false)
    setOverlayStatus(false)
  }
}

/**
 * Handles the menu overlay by adding or removing a CSS class based on the current parent menu's state.
 *
 * @param {HTMLElement} currentParentMenu - The current parent menu element.
 * @param {HTMLElement} mainMenu - The main menu element.
 *
 * If the current parent menu contains the `upArrow` class and the site brand is not 'elavon',
 * the `twoTierDarkMode` class is added to the main menu. Otherwise, the `twoTierDarkMode` class
 * is removed from the main menu.
 */
const handleMenuOverlay = (
  currentParentMenu: HTMLElement,
  mainMenu: HTMLElement
) => {
  if (currentParentMenu.classList.contains(styles.upArrow)) {
    if (process.env.SITE_BRAND !== 'elavon') {
      mainMenu.classList.add(pageHeaderStyles.twoTierDarkMode)
    }
  } else {
    mainMenu?.classList?.remove(pageHeaderStyles.twoTierDarkMode)
  }
}

/**
 * Removes parent menu items that have no inner HTML content.
 *
 * This function takes a NodeList of HTML elements, converts it to an array,
 * and iterates over each element. If an element has no inner HTML content,
 * it finds the closest parent `<li>` element and removes it from the DOM.
 *
 * @param getParentMenu - A NodeList of HTML elements representing parent menu items.
 */
const removeEmptyParentMenus = (getParentMenu: NodeListOf<HTMLElement>) => {
  const parentMenuArray = Array.from(getParentMenu)
  for (const element of parentMenuArray) {
    if (!element.innerHTML) {
      element.closest('li')?.remove()
    }
  }
}

/**
 * Adjusts the height of a submenu based on the height of its parent element.
 *
 * @param parentHeight - The height of the parent element.
 * @param grandSubMenuHeight - The height of the grand submenu element.
 * @param thisGrandSubMenu - The grand submenu element whose height needs to be adjusted.
 * @param menuLinkParent - The parent element of the menu link, which may also need its height adjusted.
 */
const adjustSubMenuHeight = (
  parentHeight: number,
  grandSubMenuHeight: number,
  thisGrandSubMenu: HTMLElement,
  menuLinkParent: HTMLElement | null
) => {
  if (parentHeight > grandSubMenuHeight) {
    thisGrandSubMenu.style.height = parentHeight + 'px'
  }
  if (parentHeight < grandSubMenuHeight) {
    if (menuLinkParent) {
      menuLinkParent.style.height = grandSubMenuHeight + 'px'
    }
  }
}

/**
 * Sets the first submenu as selected and adjusts its display and height.
 *
 * @param menuLinks - A NodeList of HTML elements representing the menu links.
 *
 * This function performs the following steps:
 * 1. Checks if the first menu link has a grand submenu.
 * 2. If a grand submenu exists, it sets its display to 'block'.
 * 3. Retrieves the height of the parent element and the grand submenu.
 * 4. Adds a 'selected' class to the first menu link.
 * 5. Adjusts the height of the submenu based on the parent and grand submenu heights.
 */
const setFirstSubMenuSelected = (menuLinks: NodeListOf<HTMLElement>) => {
  const thisGrandSubMenu = menuLinks[0].querySelectorAll(`.grandSubMenu`)
  const hasGrandSubMenu = thisGrandSubMenu.length > 0

  if (hasGrandSubMenu) {
    ;(thisGrandSubMenu[0] as HTMLElement).style.display = 'block'
    const parentHeight = menuLinks[0].parentElement?.clientHeight || 0
    const grandSubMenuHeight = thisGrandSubMenu[0].clientHeight

    menuLinks[0].classList.add(styles.selected)

    adjustSubMenuHeight(
      parentHeight,
      grandSubMenuHeight,
      thisGrandSubMenu[0] as HTMLElement,
      menuLinks[0].parentElement
    )
  }
}

/**
 * Adjusts the height of the given element and its submenu based on the height of the submenu.
 *
 * @param element - The main HTML element whose height needs to be adjusted.
 * @param getSubMenuHeight - The HTML element representing the submenu whose height is used for comparison.
 * @param subMenu - The HTML element representing the submenu whose height needs to be adjusted.
 */
const setDynamicHeight = (
  element: HTMLElement,
  getSubMenuHeight: HTMLElement,
  subMenu: HTMLElement
) => {
  if (element.offsetHeight <= getSubMenuHeight.offsetHeight) {
    element.style.height = getSubMenuHeight.offsetHeight + 'px'
    subMenu.style.height = getSubMenuHeight.offsetHeight + 'px'
  } else {
    const setHeight = element.offsetHeight + 'px'
    if (element.parentNode && element.parentNode.parentNode) {
      ;(element.parentNode.parentNode as HTMLElement).style.height = setHeight
    }
  }
}

/**
 * Hides all elements in the grandSubMenu and resets their styles.
 * If an element contains the isActiveClass, it will be displayed and its styles will be adjusted.
 * Additionally, sets the dynamic height for the subMenu and grandSubMenu.
 *
 * @param grandSubMenu - A NodeList of HTMLElements representing the grand sub-menu items.
 * @param getSubMenuHeight - An HTMLElement used to get the height of the sub-menu.
 * @param subMenu - An HTMLElement representing the sub-menu.
 * @param isActiveClass - A string representing the class name that indicates an active element.
 */
const hideGrandSubMenuElements = (
  grandSubMenu: NodeListOf<HTMLElement>,
  getSubMenuHeight: HTMLElement,
  subMenu: HTMLElement,
  isActiveClass: string
) => {
  const grandSubMenuArray = Array.from(grandSubMenu)
  for (const element of grandSubMenuArray) {
    element.style.display = 'none'

    if (element.classList.contains(isActiveClass)) {
      element.style.display = 'block'
      element.style.transform = 'none'
      element.classList.remove(isActiveClass)
      // Set dynamic height for subMenu and grandSubMenu
      setDynamicHeight(element, getSubMenuHeight, subMenu)
    }
  }
}

/**
 * Closes all profile dropdowns by removing the 'showDropdown' class from each element in the provided NodeList.
 *
 * @param profileDropdown - A NodeList of HTMLElement representing the profile dropdown elements.
 */
const closeProfileDropdowns = (profileDropdown: NodeListOf<HTMLElement>) => {
  const profileDropdownArray = Array.from(profileDropdown)
  for (const dropdown of profileDropdownArray) {
    if (dropdown.classList.contains(styles.showDropdown)) {
      dropdown.classList.remove(styles.showDropdown)
    }
  }
}

/**
 * Removes the visibility class from all sub-menu elements except the specified sibling element.
 *
 * @param subMenu - A NodeList of HTMLElement representing the sub-menu elements.
 * @param siblingList - The HTMLElement that should not have its visibility class removed.
 */
const removeSubMenuVisibility = (
  subMenu: NodeListOf<HTMLElement>,
  siblingList: HTMLElement
) => {
  const subMenuArray = Array.from(subMenu)
  for (const subMenus of subMenuArray) {
    if (subMenus != siblingList) {
      subMenus.classList.remove(styles.isVisible)
    }
  }
}

/**
 * Removes the 'upArrow' class from all parent menus except the current one.
 *
 * @param menuList - A NodeList of HTML elements representing the parent menus.
 * @param currentParentMenu - The current parent menu element that should retain the 'upArrow' class.
 */
const removeParentMenuArrows = (
  menuList: NodeListOf<HTMLElement>,
  currentParentMenu: HTMLElement
) => {
  const menuListArray = Array.from(menuList)
  for (const parentMenu of menuListArray) {
    if (parentMenu != currentParentMenu) {
      parentMenu.classList.remove(styles.upArrow)
    }
  }
}

/**
 * Removes list items containing empty product suites from the DOM.
 *
 * This function iterates over a NodeList of HTML elements representing product suites.
 * If an element's innerHTML is empty, the closest parent 'li' element is removed from the DOM.
 *
 * @param getProductSuiteList - A NodeList of HTML elements representing product suites.
 */
const removeEmptyProductSuites = (
  getProductSuiteList: NodeListOf<HTMLElement>
) => {
  const productSuiteArray = Array.from(getProductSuiteList)
  for (const element of productSuiteArray) {
    if (!element.innerHTML) {
      element.closest('li')?.remove()
    }
  }
}

/**
 * Closes all login modals and sets focus back to the login button.
 *
 * @param loginModal - A NodeList of HTML elements representing the login modals.
 * @param loginButtonRef - A React ref object pointing to the login button element.
 */
const closeLoginModals = (
  loginModal: NodeListOf<HTMLElement>,
  loginButtonRef: React.RefObject<HTMLButtonElement>
) => {
  const loginModalArray = Array.from(loginModal)
  for (const modal of loginModalArray) {
    if (modal.classList.contains(styles.showLoginModal)) {
      modal.classList.remove(styles.showLoginModal)
      const loginButton = loginButtonRef.current
      if (loginButton) {
        loginButton.focus()
      }
    }
  }
}

/**
 * HeaderTierBottom component renders the bottom tier of the page header.
 * It handles the display and interaction of the menu items, including submenus and grand submenus.
 * It also manages the login modal and profile dropdowns.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.menuItems - The list of menu items to be displayed.
 * @param {string} props.mainmenuId - The ID of the main menu.
 * @param {boolean} props.fullWidthLayout - Flag indicating if the layout should be full width.
 * @param {Object} props.authWidgetConfigData - Configuration data for the authentication widget.
 * @param {Object} props.allProductVersionsInfo - Information about all product versions.
 *
 * @returns {JSX.Element} The rendered HeaderTierBottom component.
 */
export const HeaderTierBottom = ({
  menuItems,
  mainmenuId,
  fullWidthLayout,
  authWidgetConfigData,
  allProductVersionsInfo,
}: any) => {

  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
  const featureCountryCode: boolean =
    process.env.FEATURE_COUNTRY_CODE !== 'false'
  const listMainMenu: any = useRef(null)
  const [cookies] = useCookies(['countryCode', 'locale'])
  const countryCode = (cookies.countryCode || 'US').toLowerCase()
  let mainMenuRef = useRef<Array<React.RefObject<HTMLAnchorElement>>>(
    menuItems?.map(() => createRef())
  )
  const loginButtonRef = useRef<HTMLButtonElement>(null)
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
  const [profileMenuArrowFlag, setProfileMenuArrowFlag] = useState(false)
  const { data: session } = useSession()
  const getFirstLevelMenuItemStyles = (levelMenuItem: PageHeaderMenuItem) => {
    return levelMenuItem?.hasSubMenus && !featureCountryCode
      ? styles.parentMenuLink
      : `${styles.parentMenuLinkNoSubMenu}`
  }
  const getSecondLevelMenuItemStyles = (levelMenuItem: PageHeaderMenuItem) => {
    return `${
      isElavon ? styles.dropDownSubMenuForElavon : styles.dropDownSubMenu
    } 
      ${levelMenuItem.hasSubMenus ? '' : styles.whiteBGColor}`
  }
  const closeLoginModal = () => {
    const loginModal: any = document.querySelector<HTMLElement>(`.loginModal`)
    if (loginModal && loginModal.classList.contains(styles.showLoginModal)) {
      let loginButton = loginButtonRef.current
      if (loginButton) {
        loginButton.focus()
      }
    }

    loginModal?.classList?.remove(styles.showLoginModal)
    const showOverlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
    showOverlay?.classList?.remove(pageHeaderStyles.twoTierDarkMode)
  }

  const toggleSubMenu = (e: any, levelRef: any) => {
    updateProfileMenuArrowFlag(
      profileMenuArrowFlag,
      setProfileMenuArrowFlag,
      setOverlayStatus
    )
    closeLoginModal()
    const parentMenuList = listMainMenu.current as any
    const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
    const grandSubMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    for (const element of grandSubMenu) {
      element.style.display = 'none'
    }
    const getSubMenu: any = document.querySelector<HTMLElement>(`.subMenu`)
    const menuLinks = e.currentTarget.nextElementSibling.querySelectorAll('li')
    const profileDropdown: any =
      document.querySelectorAll<HTMLElement>(`.profileDropdown`)
    for (const menuLink of menuLinks) {
      menuLink.classList.remove(styles.selected)
    }

    // Remove parent menu if no products available
    const getParentMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    removeEmptyParentMenus(getParentMenu)

    const level1Links = levelRef
    const parentMenu = e.currentTarget.closest('li')
    const siblingList = level1Links.current.nextElementSibling
    if (siblingList) {
      e.preventDefault()
      getSubMenu.style.height = 'auto'
      parentMenu.classList.toggle(styles.upArrow)
      siblingList.classList.toggle(styles.isVisible)
      const mainMenu: any = document.querySelector<HTMLElement>(`.showOverlay`)
      const menuList = parentMenuList.querySelectorAll('li')
      const currentParentMenu = e.currentTarget.closest('li')
      // Add overlay when menu clicked
      handleMenuOverlay(currentParentMenu, mainMenu)

      removeSubMenuVisibility(subMenu, siblingList)
      removeParentMenuArrows(menuList, currentParentMenu)
      // Set first submenu to be selected by default
      setFirstSubMenuSelected(menuLinks)
    }
    closeProfileDropdowns(profileDropdown)

    // Remove productSuite link if no products available
    const getProductSuiteList: any =
      document.querySelectorAll<HTMLElement>(`.product-suites`)
    removeEmptyProductSuites(getProductSuiteList)
  }
  const toggleGrandSubMenu = (e: any) => {
    if (profileMenuArrowFlag) setProfileMenuArrowFlag(!profileMenuArrowFlag)
    const isActiveClass = 'is-active'
    const grandSubMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    const level2Links = e.currentTarget
    const level2List = e.currentTarget.closest('li')
    const subMenu: any = document.querySelector<HTMLElement>(`.subMenu`)
    const getSubMenuHeight: any =
      document.querySelector<HTMLElement>(`.subMenu`)
    const menuLinks = e.currentTarget.nextElementSibling?.querySelectorAll('li')
    const parentElement = e.currentTarget.parentElement

    for (const el of parentElement.parentNode.childNodes) {
      el.classList.remove(styles.selected)
    }

    if (menuLinks) {
      for (const menuLink of menuLinks) {
        menuLink.classList.remove(styles.selected)
      }
    }
    const siblingList = level2Links.nextElementSibling

    if (siblingList && level2Links.nextElementSibling.innerHTML) {
      e.preventDefault()
      level2List.classList.add(styles.selected)
      siblingList.classList.toggle(isActiveClass)
      // Toggle grandSubMenu
      getSubMenuHeight.style.height = 'auto'
      hideGrandSubMenuElements(
        grandSubMenu,
        getSubMenuHeight,
        subMenu,
        isActiveClass
      )
    }
  }

  const closeDropdown = (event: any) => {
    const isElementInContainer = (containerId: string) => {
      const container = document.getElementById(containerId);
      return container?.contains(event?.srcElement);
    };

    const shouldHideOverlayAndMenu = () => {
      const elementInLoginModal = isElementInContainer('login-modal');
      const elementInProfileDropdown = isElementInContainer('profile-details');
      const isLoginButton = event?.srcElement?.id === 'login-btn';

      return !elementInLoginModal && !elementInProfileDropdown && !isLoginButton;
    };

    if (shouldHideOverlayAndMenu()) {
      hideOverlayAndMenu({
        profileMenuArrowFlag,
        setProfileMenuArrowFlag,
        setOverlayRendered: () => setOverlayStatus(false),
        listMainMenu,
        loginButtonRef
      });
    }
  }

  const ref: any = useDetectClickOutside({ onTriggered: closeDropdown });
  const headerDesktopBaseNavColumn = {
    span: null,
    spans: { xlarge: 16, large: 16, medium: 8, small: 4 },
    offset: { xlarge: 0, large: 0, medium: 0, small: 0 },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  const headerDesktopElavonNavColumn = {
    span: null,
    spans: { xlarge: 16, large: 16, medium: 8, small: 4 },
    offset: { xlarge: 0, large: 0, medium: 0, small: 0 },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  const headerDesktopNavColumn =
    process.env.SITE_BRAND !== 'elavon'
      ? headerDesktopBaseNavColumn
      : headerDesktopElavonNavColumn

  let renderMobileMenuItems: any
  let counter = 0
  if (menuItems) {
    renderMobileMenuItems = menuItems?.map(function (
      firstLevelMenuItem: PageHeaderMenuItem,
      firstLevelItemIndex: number
    ) {
      counter++
      return (
        <li className={styles.twoTierparentMenu} key={`${firstLevelMenuItem?.id}-${counter}`}>
          <a
            href={
              !featureCountryCode
                ? `${firstLevelMenuItem?.url}`
                : `${firstLevelMenuItem?.url}` || '#'
            }
            className={getFirstLevelMenuItemStyles(firstLevelMenuItem)}
            ref={mainMenuRef.current[firstLevelItemIndex]}
            key={firstLevelMenuItem?.id}
            data-testid={`toggleSubMenu${firstLevelItemIndex}`}
            aria-label={firstLevelMenuItem.value}
            onClick={(e: any) => {
              if (firstLevelMenuItem?.hasSubMenus && !featureCountryCode) {
                toggleSubMenu(e, mainMenuRef.current[firstLevelItemIndex])
              }
            }}
          >
            {firstLevelMenuItem.value}{' '}
          </a>
          <ul
            className={`${styles.subMenuList} ${styles.twoTeirShow} subMenu`}
          >
            {firstLevelMenuItem?.hasSubMenus &&
              firstLevelMenuItem?.menuItems?.items?.map(function (
                secondLevelMenuItem: PageHeaderMenuItem
              ) {
                return (
                  <li
                    className={getSecondLevelMenuItemStyles(
                      secondLevelMenuItem
                    )}
                    data-testid="submenu"
                    key={secondLevelMenuItem.id}
                  >
                    {secondLevelMenuItem.hasSubMenus ? (
                      <a
                        href={`${secondLevelMenuItem.url}` || '#'}
                        className={
                          styles.subMenuLink +
                          ' ' +
                          styles.grandSubMenuParentLink
                        }
                        data-testid="toggleGrandSubMenu"
                        onClick={(e: any) => {
                          toggleGrandSubMenu(e)
                        }}
                      >
                        {secondLevelMenuItem.value}
                      </a>
                    ) : (
                      <Link href={`${secondLevelMenuItem.url}` || '#'}>
                        <a
                          className={styles.subMenuLink}
                          data-testid="toggleGrandSubMenu"
                          onClick={(e: any) => {
                            toggleGrandSubMenu(e)
                            hideOverlayAndMenu({
                              profileMenuArrowFlag,
                              setProfileMenuArrowFlag,
                              setOverlayRendered: () => setOverlayStatus(false),
                              listMainMenu,
                              loginButtonRef
                            });
                          }}
                          href={`${secondLevelMenuItem.url}` || '#'}
                        >
                          {secondLevelMenuItem.value}
                        </a>
                      </Link>
                    )}

                    {secondLevelMenuItem?.hasSubMenus ? (
                      <ul
                        className={`${styles.grandSubMenuList} ${styles.drop} grandSubMenu`}
                      >
                        {secondLevelMenuItem?.hasSubMenus &&
                          secondLevelMenuItem?.menuItems?.items?.map(function (
                            thirdLevelMenuItem: PageHeaderMenuItem,
                            thirdLevelItemIndex: number
                          ) {
                            const superChild =
                              thirdLevelMenuItem?.url?.split('/')
                            const superChildPathDepth = superChild?.length || 0
                            const superChildVersion = superChild
                              ? superChild[superChild.length - 1]
                              : ''
                            const superChildProductSlug = superChild
                              ? superChild[superChild.length - 2]
                              : ''
                            let superChildProductLink = thirdLevelMenuItem?.url
                            if (!isElavon) {
                              if (
                                thirdLevelMenuItem.value &&
                                superChildPathDepth - 1 > 2
                              ) {
                                superChildProductLink =
                                  fetchAccessibleProductLink(
                                    superChildVersion,
                                    superChildProductSlug,
                                    allProductVersionsInfo,
                                    countryCode
                                  )
                              }
                            }
                            if (superChildProductLink) {
                              if (
                                featureMFA &&
                                session &&
                                getLastStepupDuration(
                                  authWidgetConfigData.MFA_WIDGET_CHALLENGE_INTERVAL_SECONDS
                                )
                              ) {
                                if (
                                  superChildProductLink == '/dashboard/usage'
                                ) {
                                  superChildProductLink = ''
                                }
                              }

                              const thirdLevelLink = (
                                <a
                                  className={styles.grandSubMenuLink}
                                  onClick={() => {
                                    hideOverlayAndMenu({
                                      profileMenuArrowFlag,
                                      setProfileMenuArrowFlag,
                                      setOverlayRendered: () => setOverlayStatus(false),
                                      listMainMenu,
                                      loginButtonRef
                                    });
                                  }}
                                >
                                  {thirdLevelMenuItem.value}
                                </a>
                              )

                              return (
                                <li key={`${thirdLevelItemIndex}-${counter}`}>
                                  {superChildProductLink && (
                                    <Link
                                      href={`${superChildProductLink}` || '#'}
                                    >
                                      {thirdLevelLink}
                                    </Link>
                                  )}
                                  {!superChildProductLink && (
                                    <>{thirdLevelLink}</>
                                  )}
                                  <ul className={`${superChildProductSlug} ${styles.fourthLevelGrandSubMenuLinkWrapper}`}>
                                    {thirdLevelMenuItem?.hasSubMenus &&
                                      thirdLevelMenuItem?.menuItems?.items?.map(
                                        function (
                                          fourthLevelMenuItem: PageHeaderMenuItem,
                                          fourthLevelItemIndex: number
                                        ) {
                                          const subChild =
                                            fourthLevelMenuItem?.url?.split('/')
                                          const subChildPathDepth =
                                            subChild?.length || 0
                                          const subChildVersion = subChild
                                            ? subChild[subChild.length - 1]
                                            : ''
                                          const subChildProductSlug = subChild
                                            ? subChild[subChild.length - 2]
                                            : ''
                                          let subChildProductLink =
                                            fourthLevelMenuItem?.url
                                          if (!isElavon)
                                            if (
                                              fourthLevelMenuItem.value &&
                                              subChildPathDepth - 1 > 2
                                            ) {
                                              subChildProductLink =
                                                fetchAccessibleProductLink(
                                                  subChildVersion,
                                                  subChildProductSlug,
                                                  allProductVersionsInfo,
                                                  countryCode
                                                )
                                            }
                                          if (subChildProductLink) {
                                            return (
                                              <li key={`${fourthLevelItemIndex}-${counter}`}>
                                                <Link
                                                  href={
                                                    `${subChildProductLink}` ||
                                                    '#'
                                                  }
                                                >
                                                  <a
                                                    className={
                                                      styles.greatGrandSubMenuLink
                                                    }
                                                    onClick={() => {
                                                      hideOverlayAndMenu({
                                                        profileMenuArrowFlag,
                                                        setProfileMenuArrowFlag,
                                                        setOverlayRendered: () => setOverlayStatus(false),
                                                        listMainMenu,
                                                        loginButtonRef
                                                      });
                                                    }}
                                                    href={
                                                      `${subChildProductLink}` ||
                                                      '#'
                                                    }
                                                  >
                                                    {fourthLevelMenuItem.value}
                                                  </a>
                                                </Link>
                                              </li>
                                            )
                                          }
                                        }
                                      )}
                                  </ul>
                                </li>
                              )
                            }
                          })}
                      </ul>
                    ) : null}
                  </li>
                )
              })}
          </ul>
        </li>
      )
    })
  }

  return (
    <>
      <USBColumn
        layoutOpts={headerDesktopNavColumn}
        addClasses={`${styles.fullWidth} ${styles.twoTierWrapper}`}
      >
        <nav>
          <div className={`${styles.threeTiernavMenuItems}`} ref={ref}>
            {countryCode !== 'oc' && (
              <ul
                className={`${styles.mainMenu} ${
                  fullWidthLayout ? styles.mainMenuFullWidth : ``
                } main`}
                ref={listMainMenu}
              >
                {renderMobileMenuItems}
              </ul>
            )}
          </div>
        </nav>
      </USBColumn>
    </>
  )
}
