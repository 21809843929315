import { removeUpArrowFromParentMenus, removeShowDropdownFromProfileDropdowns } from './HeaderTierTop'
import pageHeaderStyles from '../pageHeader.module.scss'
import styles from '../desktopNavBar.module.scss'

interface HideOverlayAndMenuParams {
  profileMenuArrowFlag: boolean,
  setProfileMenuArrowFlag: (flag: boolean) => void,
  setOverlayRendered: (status: boolean) => void,
  listMainMenu: React.RefObject<any>,
  loginButtonRef: React.RefObject<any>
}

/**
 * Hides the overlay and menu when clicked outside.
 *
 * @param {Object} params - The parameters for hiding overlay and menu.
 * @param {boolean} params.profileMenuArrowFlag - The flag indicating the state of the profile menu arrow.
 * @param {Function} params.setProfileMenuArrowFlag - Function to set the profile menu arrow flag.
 * @param {Function} params.setOverlayRendered - Function to set the overlay rendered state.
 * @param {React.RefObject} params.listMainMenu - Reference to the main menu list.
 * @param {React.RefObject} params.loginButtonRef - Reference to the login button.
 *
 * This function performs the following actions:
 * - Toggles the profile menu arrow flag.
 * - Removes the overlay class from the main menu.
 * - Sets the overlay rendered state to false.
 * - Hides all grand submenus.
 * - Removes visibility class from all submenus.
 * - Removes up arrow from parent menus.
 * - Closes the profile dropdown.
 * - Closes the login modal and focuses the login button.
 */
export const hideOverlayAndMenu = ({
  profileMenuArrowFlag,
  setProfileMenuArrowFlag,
  setOverlayRendered,
  listMainMenu,
  loginButtonRef
}: HideOverlayAndMenuParams) => {
  // Hide overlay and menu when clicked outside
  if (profileMenuArrowFlag) setProfileMenuArrowFlag(!profileMenuArrowFlag)
  const mainMenu: any = document.querySelector<HTMLElement>(`.showOverlay`)
  mainMenu.classList.remove(pageHeaderStyles.twoTierDarkMode)
  setOverlayRendered(false)
  const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
  const grandSubMenu: any = document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
  const profileDropdown: any = document.querySelectorAll<HTMLElement>(`.profileDropdown`)
  for (const element of grandSubMenu) {
    element.style.display = 'none'
  }
  for (const subMenus of subMenu) {
    subMenus.classList.remove(styles.isVisible)
  }
  const parentMenuList = listMainMenu.current as any
  if (parentMenuList) {
    const menuList = parentMenuList.querySelectorAll('li')
    removeUpArrowFromParentMenus(menuList)
  }
  // Close Profile dropdown
  removeShowDropdownFromProfileDropdowns(profileDropdown)
  // Close Login Modal
  const loginModal: any = document.querySelectorAll<HTMLElement>(`.loginModal`)
  for (const modal of loginModal) {
    if (modal.classList.contains(styles.showLoginModal)) {
      modal.classList.remove(styles.showLoginModal)
      let loginButton = loginButtonRef.current as any
      
      if (loginButton) {
        loginButton.focus()
      }
    }
  }
}
