import { getCookies, setCookie } from 'cookies-next'
import { setExpirationDays } from '@/utils/cookies/cookies'
import axios from 'axios'


const CONTENT_BASE_PATH = process.env.CONTENT_BASE_PATH
const CONTENT_DOMAIN = process.env.CONTENT_DOMAIN
const SITE_BRAND = process.env.SITE_BRAND

export const getCurrentProduct = (routerSlugList: any, currentCountry: string) => {
    return currentCountry === 'us' ? routerSlugList[0] : routerSlugList[1];
}

export const getCurrentVersion = (routerSlugList: any, currentCountry: string) => {
return currentCountry === 'us' ? routerSlugList[1] : routerSlugList[2];
}

export const isOverview = (routerSlugList: any, currentCountry: string) => {
return routerSlugList.length === (currentCountry === 'us' ? 2 : 3);
}

export const getRedirectUrl = (currentCountry: string, selectedCountry: string, routerSlugList: any, isOverviewPage: boolean) => {
    if (currentCountry === 'us') {
      return `/products/${selectedCountry}/${routerSlugList.join('/')}${isOverviewPage ? '/overview' : ''}`;
    }
    if (currentCountry !== 'us' && selectedCountry === 'us') {
      return `/products/${routerSlugList.slice(1).join('/')}${isOverviewPage ? '/overview' : ''}`;
    }
    return `/products/${selectedCountry}/${routerSlugList.slice(1).join('/')}${isOverviewPage ? '/overview' : ''}`;
}

export const handleSideNavResponse = async (sideNavResponse: any, redirectToUrl: string, router: any) => {
    if (sideNavResponse?.data?.length) {
      const sideNavData = sideNavResponse.data;
      const pageFound = sideNavData.some((item: any) => item.path === redirectToUrl);
      const finalUrl = pageFound ? redirectToUrl : redirectToUrl.split('/').slice(0, -1).join('/');
      await router.push(finalUrl);
      window.location.reload();
    }
  }

export const removePrefix = (str: string): string => {
    return str.replace(/^[a-zA-Z]{2}-/, '')
}

export const setCookies = (locale: string, countryCode: string) => {
    setCookie('locale', locale, {
      expires: setExpirationDays(90),
    })
    setCookie('countryCode', countryCode, {
      expires: setExpirationDays(90),
    })
}

const { locale, countryCode } = getCookies();

export const buildSideNavEndpoint = (selectedLocale: string, currentProduct: string, currentVersion: string) => {
  return `${CONTENT_DOMAIN}${CONTENT_BASE_PATH}${selectedLocale}/${SITE_BRAND}/products/${currentProduct}/${currentVersion}.sidenavdata.json`
}

export const handleProductRedirect = async (router: any, selectedCountry: string, selectedLocale: string) => {
  const routerSlugList = router.query.slug

  if (!routerSlugList || typeof routerSlugList === 'string') {
    await redirectToHomePage(router)
    return
  }

  const currentCountry = (countryCode || '').toLowerCase()
  const currentProduct = getCurrentProduct(routerSlugList, currentCountry)
  const currentVersion = getCurrentVersion(routerSlugList, currentCountry)
  const isOverviewPage = isOverview(routerSlugList, currentCountry)

  const redirectToUrl = getRedirectUrl(currentCountry, selectedCountry, routerSlugList, isOverviewPage)
  const sideNavEndpoint = buildSideNavEndpoint(selectedLocale, currentProduct, currentVersion)

  try {
    const sideNavResponse = await axios.get(sideNavEndpoint, { proxy: false })
    await handleSideNavResponse(sideNavResponse, redirectToUrl, router)
  } catch (err) {
    await redirectToHomePage(router)
  }
}

export const redirectToHomePage = async (router: any) => {
    router.push('/').then(() => {
        window.location.reload()
    })
}