import '@usb-shield/react-grid/dist/library/styles/index.css'
import { useEffect, useRef, useState } from 'react'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { LoginModal } from '@/modules/login-modal/LoginModal'
import USBButton from '@usb-shield/react-button'
import { USBColumn } from '@usb-shield/react-grid'
import USBLink from '@usb-shield/react-link'
import pageHeaderStyles from '../pageHeader.module.scss'
import { showSuccessToast } from '@/utils/toast/toast'
import styles from '../desktopNavBar.module.scss'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter, NextRouter } from 'next/router'
import RegionPicker from '@/components/RegionPicker/RegionPicker'
import {
  USBIconProfile,
  USBIconClose,
  USBIconBell,
} from '@usb-shield/react-icons'
import { InputSearch } from '@/components/InputSearch/InputSearch'
import { useSearchBarUtil } from '../util/SearchBarUtil'
import { ProfileDropdownMenu } from '@/components/PageHeader/components/ProfileDropdownMenu'
import { hideOverlayAndMenu } from './HideOverlayAndMenu'
import { openLoginModal } from './OpenLoginModal'
import { closeLoginModal } from './CloseLoginModal'
import { onLogoutClick } from './OnLogoutClick'
import { toggleProfileDetails } from './ToggleProfileDetails'
import { useDetectClickOutside } from 'react-detect-click-outside';

/**
 * A variable to set the status of an overlay.
 *
 * @type {any}
 */
export let setOverlayStatus: any

/**
 * Hides all elements with the class name 'grandSubMenu' by setting their display style to 'none'.
 * This function selects all elements with the class name 'grandSubMenu' and iterates through them,
 * setting their display style to 'none' to hide them from view.
 */
export const hideGrandSubMenus = () => {
  const grandSubMenu: any =
    document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
  for (const element of grandSubMenu) {
    element.style.display = 'none'
  }
}

/**
 * Removes the 'upArrow' class from each parent menu element in the provided NodeList.
 *
 * @param menuList - A NodeList of HTMLElement objects representing the parent menus.
 */
export const removeUpArrowFromParentMenus = (
  menuList: NodeListOf<HTMLElement>
) => {
  Array.from(menuList).forEach((parentMenu) => {
    parentMenu.classList.remove(styles.upArrow)
  })
}

/**
 * Removes the 'showDropdown' class from each element in the provided NodeList.
 *
 * @param profileDropdown - A NodeList of HTMLElements representing profile dropdowns.
 */
export const removeShowDropdownFromProfileDropdowns = (
  profileDropdown: NodeListOf<HTMLElement>
) => {
  Array.from(profileDropdown).forEach((dropdown) => {
    if (dropdown.classList.contains(styles.showDropdown)) {
      dropdown.classList.remove(styles.showDropdown)
    }
  })
}

/**
 * Removes parent `<li>` elements of the given elements if they are empty.
 *
 * @param elements - A NodeList of HTML elements to check for empty content.
 */
export const removeEmptyParentMenus = (elements: NodeListOf<HTMLElement>) => {
  Array.from(elements).forEach((element) => {
    if (!element.innerHTML) {
      element?.closest('li')?.remove()
    }
  })
}

/**
 * Toggles the overlay class on the main menu based on the presence of a specific class in the parent menu.
 *
 * @param parentMenu - The parent menu HTML element.
 * @param mainMenu - The main menu HTML element.
 * @param setOverlayRendered - A function to set the overlay rendered status.
 */
export const toggleOverlayClass = (
  parentMenu: HTMLElement,
  mainMenu: HTMLElement,
  setOverlayRendered: (status: boolean) => void
) => {
  if (parentMenu.classList.contains(styles.upArrow)) {
    mainMenu.classList.add(pageHeaderStyles.twoTierDarkMode)
    setOverlayRendered(true)
  } else {
    mainMenu.classList.remove(pageHeaderStyles.twoTierDarkMode)
    setOverlayRendered(false)
  }
}

/**
 * Adjusts the height of the given element and its submenu based on the height of a reference element.
 *
 * @param {HTMLElement} element - The main element whose height is to be adjusted.
 * @param {HTMLElement} getSubMenuHeight - The reference element to compare heights with.
 * @param {HTMLElement} subMenu - The submenu element whose height is to be adjusted.
 */
export const setDynamicHeight = (
  element: HTMLElement,
  getSubMenuHeight: HTMLElement,
  subMenu: HTMLElement
) => {
  if (element.offsetHeight <= getSubMenuHeight.offsetHeight) {
    element.style.height = getSubMenuHeight.offsetHeight + 'px'
    subMenu.style.height = getSubMenuHeight.offsetHeight + 'px'
  } else {
    const setHeight = element.offsetHeight + 'px'
    if (element.parentNode && element.parentNode.parentNode) {
      ;(element.parentNode.parentNode as HTMLElement).style.height = setHeight
    }
  }
}

/**
 * Toggles the overlay based on the visibility of the sub-menu.
 *
 * @param {HTMLElement} showOverlay - The overlay element to be toggled.
 * @param {HTMLElement} subMenus - The sub-menu element whose visibility is checked.
 * @param {(status: boolean) => void} setOverlayRendered - Callback function to set the overlay rendered status.
 */
export const toggleOverlayBasedOnSubMenu = (
  showOverlay: HTMLElement,
  subMenus: HTMLElement,
  setOverlayRendered: (status: boolean) => void
) => {
  if (
    showOverlay.classList.contains(pageHeaderStyles.twoTierDarkMode) &&
    !subMenus.classList.contains(styles.isVisible)
  ) {
    showOverlay.classList.remove(pageHeaderStyles.twoTierDarkMode)
    setOverlayRendered(false)
  }
}

/**
 * Determines if the overlay should be removed based on the target element and overlay state.
 *
 * @param {HTMLElement} target - The target HTML element that was interacted with.
 * @param {boolean} overlayRendered - A boolean indicating if the overlay is currently rendered.
 * @returns {boolean} - Returns true if the overlay should be removed, otherwise false.
 */
export const shouldRemoveOverlay = (
  target: HTMLElement,
  overlayRendered: boolean
) => {
  return (
    target.classList.contains(styles.closeLoginModal) ||
    target.classList.contains(styles.profileNameUpArrow) ||
    overlayRendered
  )
}

/**
 * Focuses the login button if the login modal is shown.
 *
 * @param {HTMLElement} loginModal - The login modal element.
 * @param {React.RefObject<any>} loginButtonRef - A reference to the login button element.
 */
export const focusLoginButtonIfModalShown = (
  loginModal: HTMLElement,
  loginButtonRef: React.RefObject<any>
) => {
  if (loginModal && loginModal?.classList?.contains(styles.showLoginModal)) {
    let loginButton = loginButtonRef?.current
    loginButton?.focus()
  }
}

/**
 * HeaderTierTop component renders the top tier of the page header.
 * It includes various functionalities such as menu items, search bar, login/logout, and profile details.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.menuItems - The list of menu items to be displayed.
 * @param {string} props.imageURL - The URL of the logo image.
 * @param {Object} props.mainmenuId - The ID of the main menu.
 * @param {boolean} props.fullWidthLayout - Flag to determine if the layout should be full width.
 * @param {Array} props.regionOptions - The list of region options for the region picker.
 * @param {boolean} props.isLoginSignUpDisabled - Flag to disable login/signup functionality.
 * @param {boolean} props.isSearchDisabled - Flag to disable search functionality.
 * @param {Object} props.authWidgetConfigData - Configuration data for the authentication widget.
 * @param {Object} props.allProductVersionsInfo - Information about all product versions.
 *
 * @returns {JSX.Element} The rendered HeaderTierTop component.
 */
export const HeaderTierTop = ({
  menuItems,
  imageURL,
  mainmenuId,
  fullWidthLayout,
  regionOptions,
  isLoginSignUpDisabled,
  isSearchDisabled,
  authWidgetConfigData,
  allProductVersionsInfo,
}: any) => {

  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
  const isGeoLocationEnabled: boolean = process.env.FEATURE_GEOLOCATION !== 'false'

  let allProductsLink = ''
  let allProductsLinkHref = ''

  if (menuItems && menuItems[0].menuTitle) {
    allProductsLink = menuItems[0].menuTitle
  }

  if (menuItems && menuItems[0].menuLink) {
    allProductsLinkHref = menuItems[0].menuLink
  }

  const { handleSearchKeyDown } = useSearchBarUtil()
  const queryClient = useQueryClient()
  const router = useRouter()

  const [overlayRendered, setOverlayRendered] = useState(false)

  setOverlayStatus = (status: boolean) => {
    setOverlayRendered(status)
  }

  const { data: session, status } = useSession()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const listMainMenu = useRef(null)
  const menuWrapperRef = useRef(null)
  const loginButtonRef = useRef()
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [profileArrowClass, setProfileArrowClass] = useState(styles.profileNameDownArrow);

  // MFA Challenge Widget
  const [challengeModalIsOpen, setChallengeModalIsOpen] =
    useState<boolean>(false)
  const [loginModalIsOpen, setLoginModalIsOpen] = useState<boolean>(false)
  const [callBackUrl, setCallBackUrl] = useState<string>('')
  useEffect(() => {}, [challengeModalIsOpen, callBackUrl])

  const mfaWidgetProps = {
    challengeModalIsOpen: challengeModalIsOpen,
    setChallengeModalIsOpen: setChallengeModalIsOpen,
    callBackUrl: callBackUrl,
    setCallBackUrl: setCallBackUrl,
    loginModalIsOpen: loginModalIsOpen,
  }

  const handleMenuOpenCallback = () => {
    setIsMenuOpen(true)
    hideOverlayAndMenu({
      profileMenuArrowFlag,
      setProfileMenuArrowFlag,
      setOverlayRendered,
      listMainMenu,
      loginButtonRef,
    })
  }

  const handleMenuCloseCallback = () => {
    setIsMenuOpen(false)
  }

  const closeDropdown = () => {
    handleMenuCloseCallback();
  }
  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const headerDesktopBaseNavColumn = {
    span: null,
    spans: { xlarge: 16, large: 16, medium: 8, small: 4 },
    offset: { xlarge: 0, large: 0, medium: 0, small: 0 },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  const headerDesktopElavonNavColumn = {
    span: null,
    spans: { xlarge: 16, large: 16, medium: 8, small: 4 },
    offset: { xlarge: 0, large: 0, medium: 0, small: 0 },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  const headerDesktopNavColumn =
    process.env.SITE_BRAND !== 'elavon'
      ? headerDesktopBaseNavColumn
      : headerDesktopElavonNavColumn

  const fullWidthLayoutStyledElavon = styles.fullWidth

  const fullWidthLayoutStyled =
    process.env.SITE_BRAND !== 'elavon'
      ? `${styles.fullWidth} ${styles.twoTierWrapper} ${styles.twoTierLogoWrapper}`
      : fullWidthLayoutStyledElavon

  const logoAndTaglineStyledBase = styles.twoTierlogoAndTaglineContainer

  const logoAndTaglineStyledElavon = styles.logoAndTaglineFullWidth

  const logoAndTaglineStyled =
    process.env.SITE_BRAND !== 'elavon'
      ? logoAndTaglineStyledBase
      : logoAndTaglineStyledElavon

  const profileDetailsStyledBase = styles.profileDetailsTierTop

  const profileDetailsStyledElavon = styles.profileDetailsFullWidth

  const profileDetailsStyled =
    process.env.SITE_BRAND !== 'elavon'
      ? profileDetailsStyledBase
      : profileDetailsStyledElavon

  if (process.env.FEATURE_DEVELOPER_ASSISTANT === undefined) {
    console.error('developer assistant flag unavailable')
  }

  let featureDeveloperAssistant: string =
    process.env.FEATURE_DEVELOPER_ASSISTANT || 'false'

  if (process.env.FEATURE_HEADER_NOTIFICATION === undefined) {
    console.error('notification flag unavailable')
  }

  let featureHeaderNotification: string =
    process.env.FEATURE_HEADER_NOTIFICATION || 'false'

  const [profileMenuArrowFlag, setProfileMenuArrowFlag] = useState(false)

  useEffect(() => {
    const escapeKeyPressed = (event: any) => {
      if (event.key === 'Escape') {
        hideOverlayAndMenu({
          profileMenuArrowFlag,
          setProfileMenuArrowFlag,
          setOverlayRendered,
          listMainMenu,
          loginButtonRef,
        })
      }
    }
    document.addEventListener('keydown', escapeKeyPressed, false)
  }, [
    profileMenuArrowFlag,
    setProfileMenuArrowFlag,
    setOverlayRendered,
    listMainMenu,
    loginButtonRef,
  ])

  const handleToggleProfileDetails = (e: any) => {
    toggleProfileDetails({
      e,
      listMainMenu,
      setOverlayRendered,
      profileMenuArrowFlag,
      setProfileMenuArrowFlag,
      overlayRendered,
    });
    
    if (isProfileMenuOpen) {
      setProfileArrowClass(styles.profileNameDownArrow)
    } else {
      setProfileArrowClass(styles.profileNameUpArrow)
    }
    
    setIsProfileMenuOpen(!isProfileMenuOpen);
  }

  const firstInputRef = useRef<HTMLInputElement>(null)

  const firstName = session?.decodedAccessToken?.firstName || ''
  const lastName = session?.decodedAccessToken?.lastName;

  const wrapper = isElavon
    ? styles.twoTierMenuWrapperElavon
    : styles.twoTierMenuWrapper

  const renderLoginButton = () => {
    if (isLoginSignUpDisabled || router.pathname === '/login') return null

    return (
      <>
        <USBButton
          emphasis="subtle"
          ctaStyle="standard"
          size="small"
          id="login-btn"
          addClasses={styles.loginButton}
          forwardedRef={loginButtonRef}
          handleClick={() =>
            openLoginModal({
              setLoginModalIsOpen,
              setOverlayRendered,
              listMainMenu,
              featureMFA,
            })
          }
          dataTestId="onLoginClick"
        >
          {' '}
          Log in{' '}
        </USBButton>
        <div
          className={`${
            featureMFA ? styles.loginModalMFA : styles.loginModal
          } loginModal`}
        >
          <USBButton
            type="button"
            id="close-login-modal"
            dataTestId="close-login-modal"
            ariaLabel="Close login modal"
            addClasses={styles.closeLoginModal}
            handleClick={() =>
              closeLoginModal({
                loginButtonRef,
                setOverlayRendered,
              })
            }
            forwardedRef={firstInputRef}
          >
            <span aria-hidden="true">×</span>
          </USBButton>
          <LoginModal
            overlayDarkModeClass={pageHeaderStyles.twoTierDarkMode}
            authWidgetConfigData={authWidgetConfigData}
            mfaWidgetProps={mfaWidgetProps}
          ></LoginModal>
        </div>
      </>
    )
  }

  const renderLoginOrProfile = () => {
    if (status === 'loading') return null

    return (
      <>
        {!session && !isElavon && (
          <li key="login">
            <div className={styles.navButton}>{renderLoginButton()}</div>
          </li>
        )}

        {session && (
          <li key="profile">
            <div className={profileDetailsStyled} id="profile-details">
              <a
                className={styles.profileNameTierTop}
                href="#"
                role="button"
                data-testid="toggleProfileDetails"
                onClick={handleToggleProfileDetails}
              >
                {isElavon ? (
                  <USBIconProfile
                    colorVariant="grey80"
                    size={24}
                    addClasses={styles.profileIcon}
                  />
                ) : (
                  <USBIconProfile
                    colorVariant="grey80"
                    size={25}
                    addClasses={styles.profileIcon}
                  />
                )}
                  <span className={profileArrowClass} >
                    {firstName + ' ' + lastName}
                  </span>
              </a>
              <div
                className={`${styles.profileDropdownTierTop} profileDropdown`}
              >
                <div className={styles.profileUserSection}>
                  <div className={styles.profileUserInfo}>
                    <p className={styles.profileUserName}>
                      {firstName + ' ' + lastName}
                    </p>
                    <p className={styles.profileUserEmail}>
                      {session?.decodedAccessToken?.email}
                    </p>
                  </div>
                  <USBButton
                    type="button"
                    id="close-login-modal"
                    dataTestId="close-profile-modal"
                    addClasses={styles.closeLoginModal}
                    handleClick={(e: any) => {
                      e.preventDefault()
                      handleToggleProfileDetails(e)
                    }}
                  >
                    <span aria-hidden="true">
                      {!isElavon ? (
                        'x'
                      ) : (
                        <USBIconClose
                          colorVariant="grey80"
                          size={24}
                          addClasses={styles.profileIcon}
                        />
                      )}
                    </span>
                  </USBButton>
                </div>
                <div className={styles.profilemenu}>
                  <div className={styles.profileDropdownLinks}>
                    <ProfileDropdownMenu
                      hideOverlayAndMenu={() =>
                        hideOverlayAndMenu({
                          profileMenuArrowFlag,
                          setProfileMenuArrowFlag,
                          setOverlayRendered,
                          listMainMenu,
                          loginButtonRef,
                        })
                      }
                      onLogoutClick={() =>
                        onLogoutClick({
                          setOverlayRendered,
                          signOut,
                          router: router as NextRouter,
                          showSuccessToast,
                          queryClient,
                        })
                      }
                      profileMenuArrowFlag={profileMenuArrowFlag}
                      setProfileMenuArrowFlag={setProfileMenuArrowFlag}
                      setOverlayRendered={setOverlayRendered}
                      listMainMenu={listMainMenu}
                      loginButtonRef={loginButtonRef}
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
        )} 
      </>
    )
  }

  return (
    <>
      <USBColumn
        layoutOpts={headerDesktopNavColumn}
        addClasses={fullWidthLayoutStyled}
      >
        <nav>
          <div className={wrapper} ref={menuWrapperRef}>
            <div className={logoAndTaglineStyled}>
              <USBLink href={'/'} addClasses={styles.profileLink}>
                <img src={imageURL} alt={process.env.SITE_BRAND + ' logo'} />
              </USBLink>
            </div>
            <nav className={styles.twoTierMenuNavWrapper}>
              {process.env.SITE_BRAND.toLowerCase() === 'elavon' && (
                <Link href={allProductsLinkHref}>
                  <a
                    className={`${styles.desktopNavBar || ''} ${
                      styles.parentMenuLinkNoSubMenu || ''
                    }`}
                    aria-label={allProductsLink}
                  >
                    {allProductsLink}
                  </a>
                </Link>
              )}
              <ul>
                {isGeoLocationEnabled && (
                  <li key="geolocation" ref={ref}>
                    <RegionPicker
                      options={regionOptions}
                      menuIsOpen={isMenuOpen}
                      handleMenuOpenCallback={handleMenuOpenCallback}
                      handleMenuCloseCallback={handleMenuCloseCallback}
                      selectorStyles={{}} // Add appropriate styles here
                    />
                  </li>
                )}
                {featureDeveloperAssistant === 'true' && (
                  <li key="developerAssistant">
                    <USBLink
                      href={'/'}
                      addClasses={`${styles.devAssistantLink} ${styles.devAssistantName}`}
                    >
                      <img
                        src={'/header/AI.png'}
                        alt={'Developer Assistant'}
                      />{' '}
                      <span>Developer Assistant</span>
                    </USBLink>
                  </li>
                )}
                {!isSearchDisabled && router.pathname !== '/search' && (
                  <li key="search">
                    <InputSearch
                      inputID="edp_search_input"
                      inputName="query_string"
                      handleSearchKeyDown={handleSearchKeyDown}
                    />
                  </li>
                )}
                {featureHeaderNotification === 'true' && (
                  <li key="notification">
                    <USBIconBell
                      colorVariant="grey80"
                      size={18}
                      addClasses={styles.bellIcon}
                    />
                  </li>
                )}
                {renderLoginOrProfile()}
              </ul>
            </nav>
          </div>
        </nav>
      </USBColumn>
    </>
  )
}
