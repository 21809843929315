import { ChallengeWidget } from '@/components/ChallengeWidget/ChallengeWidget'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import style from '@/modules/challenge-widget/challengeWidge.module.scss'
import USBModal, { ModalBody } from '@usb-shield/react-modal'
import { ModalCloseEventObject } from '@/models/shield.model'
import { useRouter } from 'next/router'
import { mfaJWTValidation } from '@/components/LoginWidget/services/login-service'
import { signOut } from 'next-auth/react'
import { useQueryClient } from '@tanstack/react-query'
import { showWarningToast } from '@/utils/toast/warningToast'
import { ChallengeWidgetProps } from './challengeWidget.model'
import { useServicesMutation } from '@/utils/services/services.mutation'
import { setLastStepupTime } from '@/utils/auth-widget/auth-widget'

const ChallengeWidgetModal = ({
  callBackUrl,
  challengeModalIsOpen,
  setChallengeModalIsOpen,
  authWidgetConfigData = null,
  type = 'page',
  setModalIsOpen,
  stepupCallback = null,
  cancelCallback = null,
  activityName = '',
  apiKeyState,
}: ChallengeWidgetProps) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const { addUserActivityLogMutation } = useServicesMutation()
  const ChallengeModalColumn = {
    span: null,
    spans: {
      xlarge: 16,
      large: 16,
      medium: 8,
      small: 4,
    },
    offset: {
      xlarge: 0,
      large: 0,
      medium: 0,
      small: 0,
    },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  // Activity log for step-up status - intiated, failed, completed
  const addActivityLogStepup = (status: string, activityName: string) => {
    try {
      if (activityName) {
        const userName = sessionStorage?.getItem('userId')
        status = status[0].toUpperCase() + status.slice(1).toLowerCase() // first letter capitalized
        activityName =
          activityName[0].toUpperCase() + activityName.slice(1) // first letter capitalized
        const activityLogData = {
          action: 'Stepup',
          message: `${status} step-up authentication to ${activityName}. Username: ${userName}. SiteId: ${authWidgetConfigData?.SITE_ID}.`,
        }
        addUserActivityLogMutation.mutate(
          { data: activityLogData },
          {
            onSettled(data: any | undefined, error: any) {
              if (error) {
                console.log('Error in adding activity log for stepup')
              }
            },
          }
        )
      }
    } catch (err: any) {
      console.log('Error in adding activity log for stepup')
    }
  }

  const logoutUser = () => {
    setTimeout(async () => {
      await signOut({ callbackUrl: '/login', redirect: true })
      router.push('/login').then()
      queryClient.removeQueries()
    }, 5000)
  }

  const onAuthFailure = async (response: any) => {
    console.log('onAuthFailure response', response)

    // User cancelled the request
    if (response._errorCode == '7') {
      setChallengeModalIsOpen(false)
      if (stepupCallback) stepupCallback(false)
      if (cancelCallback) cancelCallback()

      if (type == 'modal') setModalIsOpen(false)
      if (router.pathname == '/login') router.push('/')
    } else {
      //activity log
      addActivityLogStepup('failed', activityName)
    }

    // Server error
    if (response._errorCode == '0') {
      if (response._data.server_error_code == '8007') {
        showWarningToast(
          'Authentication error occurred and you will be logged out soon. Please login and try again.'
        )
        logoutUser()
      }
      setChallengeModalIsOpen(false)
      if (type == 'modal') setModalIsOpen(false)
      if (router.pathname == '/login') router.push('/')
    }
    // Server error assertion rejected
    if (
      response._errorCode == '9' ||
      response._message == 'Session rejected by server.'
    ) {
      if (
        response._data.failure_data.reason.data.code == '1501' &&
        response._data.failure_data.reason.type == 'assertion_rejected'
      ) {
        setChallengeModalIsOpen(false)
        if (type == 'modal') setModalIsOpen(false)
        showWarningToast('Something went wrong. Please try again later.')
        if (router.pathname == '/login') router.push('/')
      }
    }
    // After 3 incorrect OTP attempts
    if (
      response._errorCode == '9' ||
      response._message == 'Session rejected by server.'
    ) {
      if (response._data.failure_data.reason.type == 'locked') {
        showWarningToast(
          'Your account is temporarily locked. You are now being redirected to the login page.'
        )
        logoutUser()
      }
    }
  }

  const onAuthSuccess = async (res: any) => {
    setChallengeModalIsOpen(false)
    const username: any = sessionStorage.getItem('userId')
    const mfaJWTValidationResult =
      username && res.token
        ? await mfaJWTValidation(username, res.token, 'devportal')
        : false
    if (mfaJWTValidationResult) {
      setLastStepupTime()
      //activity log
      addActivityLogStepup('completed', activityName)
      // Enable API Keys tab if main contact receive any API Key request or other user's API Key request is approved
      if (apiKeyState) {
        history.pushState({ state: true }, '', callBackUrl)
        router.push(callBackUrl)
      } else {
        if (callBackUrl != '' && callBackUrl.charAt(0) != '#')
          window.location.href = callBackUrl
      }
      if (stepupCallback) stepupCallback(true)
    } else {
      //activity log
      addActivityLogStepup('failed', activityName)
      showWarningToast(
        'Something went wrong. You are now being redirected to the login page.'
      )
      logoutUser()
    }
  }

  return (
    <div className={style.challengeModal}>
      {authWidgetConfigData && type == 'modal' && (
        <div className={style.challengeWidgetDiv}>
          <ChallengeWidget
            onAuthSuccess={onAuthSuccess}
            onAuthFailure={onAuthFailure}
            authWidgetConfigData={authWidgetConfigData}
            activityName={activityName}
          ></ChallengeWidget>
        </div>
      )}
      {authWidgetConfigData && type !== 'modal' && (
        <USBGrid padding="zero">
          <USBColumn layoutOpts={ChallengeModalColumn}>
            <USBModal
              aria-hidden="true"
              isOpen={challengeModalIsOpen}
              handleClose={({
                clickedOutsideModal,
                escapeKeyPressed,
              }: ModalCloseEventObject) => {
                if (!clickedOutsideModal && !escapeKeyPressed) {
                  setChallengeModalIsOpen(false)
                  if (type == 'modal') {
                    setModalIsOpen(false)
                  }
                  if (router.pathname == '/login') router.push('/')
                  if (cancelCallback) cancelCallback()
                  if(stepupCallback) stepupCallback(false)
                }
              }}
            >
              <ModalBody>
                <div className={style.pageChallengeWidgetDiv}>
                  <ChallengeWidget
                    onAuthSuccess={onAuthSuccess}
                    onAuthFailure={onAuthFailure}
                    authWidgetConfigData={authWidgetConfigData}
                    activityName={activityName}
                  ></ChallengeWidget>
                </div>
              </ModalBody>
            </USBModal>
          </USBColumn>
        </USBGrid>
      )}
    </div>
  )
}

export default ChallengeWidgetModal
