import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import '@usb-shield/react-grid/dist/library/styles/index.css'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import pageHeaderStyles from './pageHeader.module.scss'
import desktopStyles from '@/components/PageHeader/desktopNavBar.module.scss'
import { MobileNavBar } from './MobileNavBar'
import Head from 'next/head'
import { fetchProductVersions } from '@/utils/products/fetch-product-version-and-user-token'
import { useProductListQuery } from '@/modules/products/services/product-list.query'
import ChallengeWidgetModal from '@/modules/challenge-widget/ChallengeWidget'
import { useEffect, useState, useRef } from 'react'
import { HeaderTierTop } from '@/components/PageHeader/components/HeaderTierTop'
import { HeaderTierBottom } from '@/components/PageHeader/components/HeaderTierBottom'
import { toggleLevel2Callback, escapeKeyPressed, togglebackOneLevelCallback } from './MobileNavBarUseEffectUtils'

const headerDefaultGridOptions = {
  ...defaultGridOptions,
  padding: 'zero',
}

export const PageHeader = (headerData: any) => {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState<boolean>(false)
  const [activityName, setActivityName] = useState<string>('')
  const [callBackUrl, setCallBackUrl] = useState<string>('')
  const regionOptions = headerData?.regionOptions
  const [profileMenuArrowFlag, setProfileMenuArrowFlag] = useState(false)
  const listMainMenu = useRef(null)
  const loginButtonRef = useRef()
  const menuWrapperRef = useRef(null)
  const toggleMenuCallback = () => {
    // Define the toggle menu callback logic here
  }

  const hideOverlayAndMenu = () => {
    // Hide overlay and menu when clicked outside
    if (profileMenuArrowFlag) setProfileMenuArrowFlag(!profileMenuArrowFlag)
    const mainMenu: any = document.querySelector<HTMLElement>(`.showOverlay`)
    if (mainMenu) {
      mainMenu.classList.remove(pageHeaderStyles.darkMode)
    }
    const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
    const grandSubMenu: any =
      document.querySelectorAll<HTMLElement>(`.grandSubMenu`)
    const profileDropdown: any =
      document.querySelectorAll<HTMLElement>(`.profileDropdown`)
    for (const element of grandSubMenu) {
      element.style.display = 'none'
    }
    for (const subMenus of subMenu) {
      subMenus.classList.remove(pageHeaderStyles.isVisible)
    }
    const parentMenuList = listMainMenu.current as any
    if (parentMenuList) {
      const menuList = parentMenuList.querySelectorAll('li')
      for (const parentMenu of menuList) {
        parentMenu.classList.remove(pageHeaderStyles.upArrow)
      }
    }
    // Close Profile dropdown
    for (const dropdown of profileDropdown) {
      if (dropdown.classList.contains(pageHeaderStyles.showDropdown)) {
        dropdown.classList.remove(pageHeaderStyles.showDropdown)
      }
    }

    // Close Login Modal
    const loginModal: any =
      document.querySelectorAll<HTMLElement>(`.loginModal`)
    for (const modal of loginModal) {
      if (modal.classList.contains(pageHeaderStyles.showLoginModal)) {
        modal.classList.remove(pageHeaderStyles.showLoginModal)
        let loginButton = loginButtonRef.current as any
        loginButton.focus()
      }
      
      if (modal.classList.contains(desktopStyles.showLoginModal)) {
        modal.classList.remove(desktopStyles.showLoginModal)
      }
    }
  }

  const type = headerData?.type
  const menuItems = headerData?.menuItems
  const imageURL = headerData?.imageURL
  const mainmenuId = headerData?.mainmenuId
  const fullWidthLayout = headerData?.fullWidthLayout || false
  const pageTitle = headerData?.title ? headerData.title : false
  const { useProductVersionsListQuery } = useProductListQuery()
  const { data: productListData } = useProductVersionsListQuery()
  const allProductVersionsInfo = fetchProductVersions(productListData)
  const [challengeModalIsOpen, setChallengeModalIsOpen] = useState<boolean>(false)
  
  useEffect(() => {}, [challengeModalIsOpen, callBackUrl])

  useEffect(() => {
    document.addEventListener('keydown', (event: any) => escapeKeyPressed(event, menuWrapperRef, toggleMenuCallback), false)
    return () => {
      document.removeEventListener('keydown', (event: any) => escapeKeyPressed(event, menuWrapperRef, toggleMenuCallback), false)
    }
  }, [])

  if (process.env.HEADER_TIER_BOTTOM_ENABLED === undefined) {
    console.error('undefined header environment variable')
  }

// override to add region data for usbank
const MockRegionOptions: any = [  
    {           
      label: 'North America',
      options: [
        {
          label: 'English',
          value: 'US',
          image: '/content/dam/developer-portal-solutions/en-us/elavon/media/images/country-flags/usa-flag.svg'
        },
        {
          label: 'French',
          value: 'CA',
          image: '/content/dam/developer-portal-solutions/en-us/elavon/media/images/country-flags/canada-flag.svg'
        }
      ]
    }
  ]

  return (
    <>
      <Head>
      {pageTitle &&
        <title>{pageTitle}</title>
      }
        <meta
          name="description"
          key="description"
          content="The API Dev Portal provides comprehensive documentation for use by API developers."
          />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {process.env.HEADER_TIER_BOTTOM_ENABLED === 'true' && <hr className={pageHeaderStyles.twoTierMenuSeperator}></hr>}
      <div className={process.env.SITE_BRAND.toLowerCase() === 'elavon' ? pageHeaderStyles.desktopNavBar: pageHeaderStyles.twoTierNavBar}>
        <section className={pageHeaderStyles.sectionRow}>
          <USBGrid
            alignItems={defaultGridOptions.alignItems}
            columnCount={defaultGridOptions.columnCount}
            justifyContent={defaultGridOptions.justifyContent}
            display={defaultGridOptions.display}
            padding={process.env.SITE_BRAND.toLowerCase() === 'elavon' ? defaultGridOptions.padding : 'zero'}
            addClasses={(fullWidthLayout ? pageHeaderStyles.gridWrapperFullWidth : pageHeaderStyles.gridWrapper) + ' wrapperFullWidth'}
          >
            {/* render MFA Challenge widget modal */}
            {challengeModalIsOpen && (
              <ChallengeWidgetModal
                callBackUrl={callBackUrl}
                challengeModalIsOpen={challengeModalIsOpen}
                setChallengeModalIsOpen={setChallengeModalIsOpen}
                authWidgetConfigData={headerData.authWidgetConfigData}
                activityName={activityName}
              ></ChallengeWidgetModal>
            )}
            <div
              className="showOverlay"
              data-testid="hideOverlayAndMenu"
              onClick={hideOverlayAndMenu}
            ></div>
            {/* Desktop Menu */}
            <HeaderTierTop
              type={type}
              menuItems={process.env.SITE_BRAND.toLowerCase() === 'elavon' ? menuItems : null}
              imageURL={imageURL}
              mainmenuId={mainmenuId}
              fullWidthLayout={false}
              regionOptions={regionOptions}
              isLoginSignUpDisabled={headerData.isLoginSignUpDisabled}
              isSearchDisabled={headerData.isSearchDisabled}
              allProductVersionsInfo={allProductVersionsInfo}
              authWidgetConfigData={headerData.authWidgetConfigData}
            ></HeaderTierTop>
            {process.env.HEADER_TIER_BOTTOM_ENABLED === 'true' && (
              <HeaderTierBottom
                  menuItems={menuItems}
                  mainmenuId={mainmenuId}
                  fullWidthLayout={fullWidthLayout}
                  authWidgetConfigData={headerData.authWidgetConfigData}
                  allProductVersionsInfo={allProductVersionsInfo}
              ></HeaderTierBottom>
            )}
          </USBGrid>
        </section>
      </div>
      <div className={process.env.SITE_BRAND.toLowerCase() === 'elavon' ? pageHeaderStyles.mobileNavBar : pageHeaderStyles.twoTierMobileNavBar}>
        <section className={pageHeaderStyles.sectionRow}>
          <USBGrid
            gridGap={defaultGridOptions.gridGap}
            alignItems={defaultGridOptions.alignItems}
            columnCount={defaultGridOptions.columnCount}
            justifyContent={defaultGridOptions.justifyContent}
            display={defaultGridOptions.display}
            padding={headerDefaultGridOptions.padding}
          >
            {/* Mobile Menu */}
            <MobileNavBar
              type={type}
              menuItems={menuItems}
              imageURL={imageURL}
              mainmenuId={mainmenuId}
              regionOptions={regionOptions}
              isLoginSignUpDisabled={headerData.isLoginSignUpDisabled}
              isSearchDisabled={headerData.isSearchDisabled}
              allProductVersionsInfo={allProductVersionsInfo}
              authWidgetConfigData={headerData.authWidgetConfigData}
              toggleLevel2Callback={toggleLevel2Callback}
              togglebackOneLevelCallback={togglebackOneLevelCallback}
            ></MobileNavBar>
          </USBGrid>
        </section>
      </div>
    </>
  )
}